<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-row no-gutters align="center">
        <v-col justify="center" align-self="center" cols="4">
          <div class="capitalize font-weight-regular">service</div>
        </v-col>
        <v-col align-self="start" cols="8">
          <v-autocomplete
            placeholder="Sélectionner un service"
            :items="services"
            item-text="name"
            item-value="id"
            no-data-text="aucun service"
            v-model="serviceId"
            :readonly="!enableServiceInput"
            clearable
            @click:clear="clearService"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text class="pb-0">
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="center" cols="4">
          <div class="label-field capitalize font-weight-regular">
            fonction digitale
          </div>
        </v-col>
        <v-col align-self="start" cols="8">
          <v-autocomplete
            placeholder="Sélectionner une fonction digitale"
            :items="functionsByService"
            item-text="name"
            item-value="id"
            no-data-text="aucune fonction"
            v-model="functionId"
            :readonly="!enableFunctionInput"
            clearable
            @click:clear="clearFunction"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-row no-gutters class="mb-5 mt-1" align="center">
        <v-col justify="center" align-self="center" cols="4">
          <div class="label-field capitalize font-weight-regular">référent</div>
        </v-col>
        <v-col align-self="start" cols="8">
          <v-autocomplete
            :items="users"
            v-model="user"
            :search-input.sync="search"
            no-data-text="aucun référent"
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-show="showInfo">
      <v-alert type="info" border="left" text color="#E22F92"
        ><div style="opacity: 1" v-show="notInEstablishment">
          <span
            >Le collaborateur <strong>ne fait pas parti</strong> de
            l'établissement.</span
          >
        </div>
      </v-alert>
    </v-card-text>

    <v-card-text v-show="hasNotThisFunction">
      <v-alert type="error" border="left" text
        ><div style="opacity: 1">
          <span
            >Le collaborateur n'a pas la fonction digitale
            <strong>{{ functionName }}</strong
            >.</span
          >
        </div>
      </v-alert>
    </v-card-text>

    <v-card-text v-show="alreadyExists">
      <v-alert type="error" border="left" text
        ><div style="opacity: 1">
          <span>Cette association existe déjà pour cet établissement.</span>
        </div>
      </v-alert>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="ma-2 px-4 btn" color="primary" outlined @click="onClose">
        <div class="capitalize btn">annuler</div>
      </v-btn>
      <v-btn
        class="ma-2 px-4 btn"
        color="primary"
        outlined
        :disabled="!canAddReferent || alreadyExists"
        @click="onAction"
      >
        <div class="capitalize">
          {{ actionLabel }}
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { UsersService } from "@/service/user/dg_user_service.js";
import * as logger from "@/tools/logger.js";

export default {
  name: "AddReferentDialog",

  props: {
    /**Le titre du popup */
    title: {
      type: String,
      default: "?",
    },
    /**Le libellé du bouton action */
    actionLabel: {
      type: String,
      default: "Ajouter",
    },
    /**les services */
    services: {
      type: Array,
      default: () => [],
    },
    /**les fonctions */
    functions: {
      type: Array,
      default: () => [],
    },
    /**Lidentifiant de l'établissement édité */
    establishmentId: {
      type: Number,
      default: null,
    },
    action: {
      type: Function,
      default: () => {
        logger.debug("à implémenter");
      },
    },
    /**les données sources : pour faire des vérifications (unicité, etc)  */
    source: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /**le service d'accès aux collaborateurs */
      serviceUsers: null,

      /**L'identifiant du service sélectionné. */
      serviceId: null,

      /**L'identifiant de la fonction sélectionnée. */
      functionId: null,

      /**les utilisateurs retournés lors de la recherche incrémentale. */
      users: [],

      /**le référent sélectionné */
      user: null,

      /**l'email pour la recherche incrémentale. */
      search: null,

      lastSearchTimestamp: Date.now(),

      loading: false,

      /**Le collaborateur n'est pas dans cet établissment. */
      notInEstablishment: false,

      /**Le collaborateur n'a pas cette fonction. */
      hasNotThisFunction: false,

      /**L'utilisateur complet (avec ses fonctions et établissement) */
      target: null,
    };
  },
  methods: {
    findReferents(email) {
      if (!email || email == "") {
        //le texte de recherche est vide, on resert

        this.user = null;
        this.users = [];
        return;
      }

      if (email.length < 3) {
        this.users = [];
        return;
      }

      let now = Date.now();
      this.lastSearchTimestamp = now;

      this.loading = true;

      //on lance la recherche par email
      this.serviceUsers
        .searchDgUserByEmail(email, now)
        .then((results) => {
          //on vérifie que l'on ne garde pas une réponse plus ancienne (à cause du traitement de la requête)
          if (results.ts < this.lastSearchTimestamp) {
            //console.error("trop tard");
            return;
          }

          let users = [];
          results.users.forEach((user) => {
            users.push({ text: user.email, value: user });
          });

          this.users = users;
        })
        .catch((err) => {
          console.error("problème lors de la recherche par email:" + err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkReferent(user) {
      //   console.log(
      //     "check:" + JSON.stringify(user, null, 4) + " f:" + this.functionId
      //   );
      this.notInEstablishment = false;
      this.hasNotThisFunction = false;

      if (!user || !this.functionId) {
        return;
      }

      //vérification de l'établissement
      if (user.establishment !== this.establishmentId) {
        this.notInEstablishment = true;
      } else {
        this.notInEstablishment = false;
      }

      //vérification de la fonction
      let functions = [];
      functions.push(user.function); //on ajoute la fonction principale
      functions.push(...user.secondaryFunctions); //on ajoute les fonctions secondaires

      if (!functions.includes(this.functionId)) {
        this.hasNotThisFunction = true;
      } else {
        this.hasNotThisFunction = false;
      }
    },
    onAction() {
      this.action(this.functionId, this.user.uuid);
      this.serviceId = null;
      this.functionId = null;
      this.user = null;
      this.target = null;
    },
    clearService() {
      this.serviceId = null;
      this.functionId = null;
      this.user = null;

      this.resetInfo();
    },
    clearFunction() {
      this.functionId = null;
      this.user = null;

      this.resetInfo();
    },
    resetInfo() {
      this.notInEstablishment = false;
      this.hasNotThisFunction = false;
    },
    /**L'utilisateur ferme le dialog */
    onClose() {
      this.resetInfo();
      this.serviceId = null;
      this.functionId = null;
      this.target = null;
      this.user = null;
      this.$emit("close");
    },
    dump() {
      logger.debug("target:" + JSON.stringify(this.target, null, 4));
      logger.debug("user:" + JSON.stringify(this.user, null, 4));
    },
  },
  watch: {
    search(value) {
      this.findReferents(value);
    },
    async user(value) {
      if (!value) {
        this.notInEstablishment = false;
        this.hasNotThisFunction = false;
        return;
      }
      //   console.log("user: " + JSON.stringify(value, null, 4));
      this.loading = true;
      try {
        console.log("value: " + JSON.stringify(value));
        let target = await this.serviceUsers.getByUuid(value.uuid);
        this.target = target;
        this.checkReferent(target);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    functionId(value) {
      if (!value) {
        return;
      }

      this.checkReferent(this.target);
    },
  },
  computed: {
    enableServiceInput() {
      return !this.functionId;
    },
    enableFunctionInput() {
      return this.serviceId;
    },
    functionsByService() {
      if (!this.serviceId) {
        return [];
      }

      return this.functions.filter((f) => f.serviceId === this.serviceId);
    },
    /**Afficher les informations du collaborateur. */
    showInfo() {
      return this.notInEstablishment || this.hasNotThisFunction;
    },
    canAddReferent() {
      return (
        this.user &&
        this.functionId &&
        this.serviceId &&
        !this.hasNotThisFunction
      );
    },
    functionName() {
      if (this.functionId) {
        return this.functions.find((f) => f.id === this.functionId).name;
      } else {
        return "";
      }
    },
    /**Retourne true si le couple fonction/référent existe déjà  */
    alreadyExists() {
      if (this.functionId && this.user) {
        return this.source.find(
          (e) =>
            e.function === this.functionId &&
            e.referentsUuid.includes(this.user.uuid) //TODO
        );
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.serviceUsers = new UsersService(this.$api.getUsersApi());
  },
};
</script>

<style></style>
