<template>
  <v-dialog v-model="show" width="400px">
    <v-card>
      <v-card-title class="mb-4"> Confirmer la suppression </v-card-title>
      <v-card-text><span v-html="label"></span></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="iDontWantToDelete()"
          class="btn ma-2 px-4"
        >
          Annuler
        </v-btn>

        <v-btn color="primary" @click="iWantToDelete" class="btn ma-2 px-4">
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteTableItemDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "-",
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: this.visible,
    };
  },
  methods: {
    /**la suppression n'est pas confirmée. on ferme le dialog.*/
    iDontWantToDelete() {
      this.close();
    },
    /**la suppression est confirmée.*/
    iWantToDelete() {
      this.$emit("confirmed", this.item);
      this.close();
    },

    /**Fermer le dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
  },
  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.$emit("update:visible", false);
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
