var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "400px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mb-4" }, [
            _vm._v(" Confirmer la suppression "),
          ]),
          _c("v-card-text", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn ma-2 px-4",
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.iDontWantToDelete()
                    },
                  },
                },
                [_vm._v(" Annuler ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn ma-2 px-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.iWantToDelete },
                },
                [_vm._v(" Supprimer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }